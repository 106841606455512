export default {
  de: {
    hall: 'Halle',
    hall_freig: 'Freigelände',
    // plan: 'Hallenplan',
    plan_choose: 'Halle wählen',
    // plans: 'Übersicht',
    themes: 'Themen',
    topicareas: 'Themenbereiche',
    topics: 'Themen',
  },
  fr: {
    hall: 'Hall',
    hall_freig: 'Plein air',
    // plan: 'Plan du hall',
    plan_choose: 'Chercher un hall',
    plans: 'Aperçu',
    themes: 'Thèmes',
    topicareas: 'Domaines thématiques',
    topics: 'Thèmes',
  },
  en: {
    hall: 'Hall',
    hall_freig: 'Outdoor areas',
    // plan: 'Hall plan',
    plan_choose: 'Search hall',
    plans: 'Overview',
    themes: 'Topics',
    topicareas: 'Topics',
    topics: 'Topics',
  }
}