<template>
  <div>
    <main v-if="instance">
      <Map
        :instance="instance"
        class="map"
      />
    </main>

    <main v-else>
      <Home/>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: { 
    Home: () => import('@/views/Home'),
    Map: () => import('@/views/Map')
  },
  computed: {
    instance () {
      return window.location.pathname?.substring(1)
    }
  }
}
</script>

<style>
.map {
  width: 100vw;
  aspect-ratio: 4/3;
}
</style>