import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: null,
    hallKey: null, // h_3_2
    pos: null // string: 'x,y'
  },
  mutations: {
    setData (state, data) {
      state.data = data || null
    },
    setHallKey (state, hallKey) {
      state.hallKey = hallKey
    },
    setPos (state, pos) {
      state.pos = pos
    }
  }
})
