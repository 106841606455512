import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import store from './store'
import './plugins/firebase'
import './plugins/buefy'
import '@/assets/bulma.scss'
import '@/assets/styles.css'

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  computed: {
    dev () {
      return process.env.NODE_ENV === 'development'
    }
  },
  render: h => h(App)
}).$mount('#app')
