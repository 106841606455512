import Vue from 'vue'

import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator, doc, getDoc } from 'firebase/firestore'


const USE_EMULATOR_SUITE = location.hostname === 'localhost'
const LIVE = !USE_EMULATOR_SUITE &&
  location.hostname.indexOf('-test') === -1 &&
  document.currentScript.src.indexOf('-test') === -1 // widget in staging env

const firebaseConfig = LIVE ? {
  apiKey: "AIzaSyA2QJlVNscGb4IomECBNyRKSryvyqgQrjk",
  authDomain: "mein-standort-2021.firebaseapp.com",
  projectId: "mein-standort-2021",
  storageBucket: "mein-standort-2021.appspot.com",
  messagingSenderId: "410506922399",
  appId: "1:410506922399:web:c65587fc55eca5e4c695aa",
  measurementId: "G-S7Z2LLLP56"
} : {
  apiKey: "AIzaSyCyuQ44rOcriImM3AHa7rNw5vl2ID1nIaE",
  authDomain: "mein-standort-test.firebaseapp.com",
  projectId: "mein-standort-test",
  storageBucket: "mein-standort-test.appspot.com",
  messagingSenderId: "637362232013",
  appId: "1:637362232013:web:23c311a8e3def486c72784"
}

const PROJECT_ID = firebaseConfig.projectId


const app = initializeApp(firebaseConfig)


const db = getFirestore(app)
if (USE_EMULATOR_SUITE) {
  connectFirestoreEmulator(db, 'localhost', 5002)
}

const readDoc = async (path) => {
  const snap = await getDoc(doc(db, path))
  return { id: snap.id, ...snap.data() }
}

// const fn = getFunctions(app, FUNCTIONS_REGION)
// if (USE_EMULATOR_SUITE) {
//   connectFunctionsEmulator(fn, 'localhost', 5001)
// }

// const getFn = (name) => {
//   return httpsCallable(fn, name)
// }


// const fs = getStorage(app)
// if (USE_EMULATOR_SUITE) {
//   connectStorageEmulator(fs, 'localhost', 9199)
// }


const $fb = {
  app,
  // auth,
  db,
  // fn,
  // fs,
  readDoc,
  // getFn
}

Vue.prototype.$fb = $fb

export default $fb

export {
  USE_EMULATOR_SUITE,
  LIVE,
  PROJECT_ID
}
